import { defineStore } from 'pinia';
import type { Organization } from './organization';

export interface AccessToken {
  id: string;
  description: string;
  lastLoginAt: string;
  expirationAt?: string;
  scopes: ApiPermissions[];
  expired: boolean;
}
export type ApiPermissions =
  | 'API_ACCOUNTING'
  | 'API_ADD_PNB_IP'
  | 'API_CANCEL_DOWNLOADED_SALE'
  | 'API_CANCEL_SALE'
  | 'API_CATALOG'
  | 'API_COURRIER'
  | 'API_LOAN'
  | 'API_MARKET_MANAGEMENT'
  | 'API_SALE'
  | 'API_ORGANISATION';

export type MfaTypes = 'OTP' | 'SMS';

export interface User {
  id?: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  username: string;
  isRoot: boolean;
  language: string;
  tokens?: AccessToken[];
  accessTokenScopesAvailable?: ApiPermissions[];
  mfaTypes: MfaTypes[];
  smsNeedsConfirmation?: boolean | null;
  organization: Partial<Organization> | null;

  /* methods */
  exists?: boolean;
}

export const useUser = defineStore('user', {
  state: (): User => {
    return {
      id: undefined,
      email: '',
      firstName: '',
      lastName: '',
      fullName: '',
      username: '',
      language: 'fr-FR',
      isRoot: false,
      mfaTypes: [],
      smsNeedsConfirmation: undefined,
      organization: null,
      tokens: [],
      accessTokenScopesAvailable: [],
    };
  },

  getters: {
    exists(): boolean {
      return !!this.id;
    },
  },

  actions: {
    update(data: Partial<User>) {
      this.id = data?.id || this.id;
      this.email = data?.email || this.email;
      this.firstName = data?.firstName || this.firstName;
      this.lastName = data?.lastName || this.lastName;
      this.fullName = data?.fullName || this.fullName;
      this.isRoot = data?.isRoot || this.isRoot;
      this.language = data?.language || this.language;
      this.username = data?.username || this.username;
      this.tokens = data?.tokens || this.tokens;
      this.accessTokenScopesAvailable = data?.accessTokenScopesAvailable || this.accessTokenScopesAvailable;
      this.mfaTypes = data?.mfaTypes || this.mfaTypes;
      this.smsNeedsConfirmation = data?.smsNeedsConfirmation || this.smsNeedsConfirmation;
      if (data?.organization || this.organization) {
        const org = data?.organization || this.organization;
        this.organization = (org) ? {
          id: org.id,
          broadcasterId: org.broadcasterId,
          name: org.name,
          identifier: org.identifier,
          nature: org.nature,
          currency: org.currency,
          sellForLoans: org.sellForLoans,
          countryReferencePrice: org.countryReferencePrice,
        } : null;
      }

      return this;
    },
  },
});
